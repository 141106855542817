.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap-reverse;
  padding: 3rem 1rem;
}
.footer-horizontal-line {
  display: none;
}
.footer-logo-social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 2rem;
}
.footer-social-network-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}
.footer .footer-logo {
  width: 180px;
}
.social-network-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.social-network-row.icons-container {
  width: 50%;
  align-self: center;
  justify-content: space-around;
}
.social-network-title {
  font-size: 1.1rem;
  margin: 2rem auto;
}
.social-network-icon {
  width: 30%;
  max-width: 60px;
  margin: 8px;
}
.social-network-img {
  width: 100%;
  transition: opacity 0.3s;
}
.social-network-img:hover {
  opacity: 0.5;
}
.footer-items-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-around;
}
.footer-sub-items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.footer-item-container {
  flex-flow: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 3rem;
}
.footer-items-container a {
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
}
.footer-items-container a:hover {
  color: #4b4b4b;
  text-decoration: none;
}
.footer-item-link {
  font-weight: 650;
}
.footer-item-container p {
  font-size: 1.2rem;
}
.logo-corfo img {
  width: 200px;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .footer {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    padding: 2rem 0;
  }
  .footer-horizontal-line {
    display: block;
    width: 40vw;
    height: 2px;
    background-color: #73b4e6;
  }
  .footer .footer-logo {
    width: 160px;
    margin-left: 15px;
  }
  .footer-logo-social-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 0;
  }
  .social-network-title{
    margin:0;
  }
  .footer-social-network-container {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: wrap;
    padding-left: 20px;
  }
  .social-network-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .social-network-row.icons-container {
    width: 50%;
    align-self: flex-start;
    justify-content: flex-start;
  }
  .footer-items-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
  .footer-item-container {
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
  }
  .footer-sub-items-container {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer-item-container a {
    font-size: 1rem;
  }
  .footer-item-container p {
    font-size: 1rem;
  }
}
