@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,500,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:normal,bold&display=swap');
@font-face {
  font-family: 'lemonDrop';
  src: url('/fonts/Lemondrop.ttf');
}

:root {
  --light-green: #2fb984;
  --dark-blue: #14202d;
  --pink: #fc729a;
  --white: #ffffff;
  --light-grey: #9b9b9b;
  --light-grey-bg: #ececec;

  --text-light-green: #4bb676;
  --text-black: #252525;
  --text-error: #f16856;
}

html {
  color: var(--text-black);
}

button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

button:hover {
  /* opacity: 0.7; */
  background-color: var(--light-green);
  border: none;
  color: var(--white);
}

select {
  width: 150px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #c6c6c6;
  padding-left: 10px;
}

input {
  height: 30px;
  border-radius: 2px;
  border: 1px solid #c6c6c6;
  padding-left: 10px;
}

textarea {
  height: 100px;
  border-radius: 2px;
  border: 1px solid #c6c6c6;
  padding: 10px;
  width: 100%;
}

body {
  color: var(--text-black);
  font-family: 'Source Sans Pro';
}

.title {
  font-family: 'lemonDrop';
}

.dark-blue-button {
  height: 30px;
  background-color: var(--dark-blue);
  width: 100px;
  border-radius: 2px;
  cursor: pointer;
  color: var(--white);
}

.transparent-button {
  height: 30px;
  border: 1px solid var(--dark-blue);
  width: 100px;
  border-radius: 2px;
  cursor: pointer;
}

.light-green-button {
  height: 30px;
  background-color: var(--light-green);
  width: 100px;
  border-radius: 2px;
  cursor: pointer;
  color: var(--white);
}

.add-to-cart-button {
  width: 140px;
  min-width: 140px;
  text-align: left;
  padding-left: 10px;
  background-image: url('/img/cart-icon-white.png');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 15px;
}

.error-message {
  color: var(--text-error);
  font-size: 12px;
  padding-bottom: 10px;
}
