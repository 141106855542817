.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 12vh;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1250px;
  margin: 0 auto;
}

.navbar-logo {
  width: 11vh;
  padding-right: 0px;
  padding: 0.5vh;
}

.navbar-tabs-container {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-grow: 1;
}

.navbar-tab {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
}

.navbar-tab .tab-button {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.active-tab {
  border-bottom: 3px solid var(--text-black);
}

.active-tab > button {
  font-weight: 700;
}

.navbar-sub-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px;
  width: 100%;
  background-color: var(--white);
}

.navbar-double-sub-container {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.navbar-tab:hover .navbar-sub-container {
  display: block;
}

.navbar-tab:hover button {
  /* opacity: 0.5; */
  background-color: unset;
  color: var(--light-green);
}
.navbar-tab.green-button:hover button {
  background:#00a16d;
  color: white;
}


.navbar-burger-button {
  display: flex;
}

.shopping-button-container {
  display: flex;
  margin-left: auto;
}
.navbar-shopping-button {
  font-weight: 600;
}

/* responsiveness */
@media (min-width: 1024px) {
  .navbar-container {
    height: 120px;
    padding-left: 20px;
    padding-right: 50px;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
  }
  .shopping-button-container {
    display: none;
  }
  .navbar-logo {
    width: 150px;
    padding-right: 30px;
  }

  .navbar-tabs-container {
    display: flex;
  }

  .navbar-burger-button {
    display: none;
  }
  .light-green-button.desktop{
    padding: 5px 10px;
    height: 40%;
  }
  .light-green-button.desktop:hover{
    background:#00a16d;
    color:white;
  }
}
