.toggle-button {
  height: 30px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  padding: 0;
  margin-right: 20px;
  box-sizing: border-box;
}

.toggle-button:hover {
  background-color: unset;
  opacity: 0.7;
}

.toggle-button-line {
  width: 30px;
  height: 3px;
  background: var(--dark-blue);
}

@media (min-width: 768px) {
  .toggle-button {
    margin-right: 0px;
  }
}

@media (min-width: 1025px) {
  .toggle-button {
    display: none;
    margin-right: 20px;
  }
}
