.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 100;
  top: 0;
  left: 0;
}

.backdrop:hover {
  background-color: rgba(0, 0, 0, 0.0);
}
