.side-drawer {
  max-height: 0;
  overflow: hidden;
  height: auto;
  background: rgba(255, 255, 255, 1.0);
  box-shadow: 2px 7px 8px rgba(0,0,0,.3);
  position: fixed;
  top: 12vh; /* from height of mobile navbar */
  left: 0;
  width: 100%;
  max-width: 200px;
  z-index: 200;
  transition: max-height 0.2s ease-in;
}

.open {
  max-height: 500px;
}

.side-drawer ul {
  height: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 0;
  padding: 12px 40px;
}

.side-drawer li:hover {
  background-color: rgb(235, 235, 235)
}

.side-drawer a {
  text-decoration: none;
  color: rgb(37,37,37);
  font-size: 1.4rem;
  font-weight: 400;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: rgb(37,37,37);
}


@media screen and (min-width: 360px) {
  .side-drawer {
    max-width: 240px;
  }
}

@media screen and (min-width: 1025px) {
  .side-drawer {
    display: none;
    max-width: 280px;
  }
}
