@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,500,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:normal,bold&display=swap);
.topbar-logo {
  max-width: 100px;
}

.login-logo {
  max-width: 250px;
}

.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 12vh;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1250px;
  margin: 0 auto;
}

.navbar-logo {
  width: 11vh;
  padding-right: 0px;
  padding: 0.5vh;
}

.navbar-tabs-container {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-grow: 1;
}

.navbar-tab {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
}

.navbar-tab .tab-button {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.active-tab {
  border-bottom: 3px solid var(--text-black);
}

.active-tab > button {
  font-weight: 700;
}

.navbar-sub-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px;
  width: 100%;
  background-color: var(--white);
}

.navbar-double-sub-container {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.navbar-tab:hover .navbar-sub-container {
  display: block;
}

.navbar-tab:hover button {
  /* opacity: 0.5; */
  background-color: unset;
  color: var(--light-green);
}
.navbar-tab.green-button:hover button {
  background:#00a16d;
  color: white;
}


.navbar-burger-button {
  display: flex;
}

.shopping-button-container {
  display: flex;
  margin-left: auto;
}
.navbar-shopping-button {
  font-weight: 600;
}

/* responsiveness */
@media (min-width: 1024px) {
  .navbar-container {
    height: 120px;
    padding-left: 20px;
    padding-right: 50px;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
  }
  .shopping-button-container {
    display: none;
  }
  .navbar-logo {
    width: 150px;
    padding-right: 30px;
  }

  .navbar-tabs-container {
    display: flex;
  }

  .navbar-burger-button {
    display: none;
  }
  .light-green-button.desktop{
    padding: 5px 10px;
    height: 40%;
  }
  .light-green-button.desktop:hover{
    background:#00a16d;
    color:white;
  }
}

.toggle-button {
  height: 30px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  padding: 0;
  margin-right: 20px;
  box-sizing: border-box;
}

.toggle-button:hover {
  background-color: unset;
  opacity: 0.7;
}

.toggle-button-line {
  width: 30px;
  height: 3px;
  background: var(--dark-blue);
}

@media (min-width: 768px) {
  .toggle-button {
    margin-right: 0px;
  }
}

@media (min-width: 1025px) {
  .toggle-button {
    display: none;
    margin-right: 20px;
  }
}

.side-drawer {
  max-height: 0;
  overflow: hidden;
  height: auto;
  background: rgba(255, 255, 255, 1.0);
  box-shadow: 2px 7px 8px rgba(0,0,0,.3);
  position: fixed;
  top: 12vh; /* from height of mobile navbar */
  left: 0;
  width: 100%;
  max-width: 200px;
  z-index: 200;
  transition: max-height 0.2s ease-in;
}

.open {
  max-height: 500px;
}

.side-drawer ul {
  height: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 0;
  padding: 12px 40px;
}

.side-drawer li:hover {
  background-color: rgb(235, 235, 235)
}

.side-drawer a {
  text-decoration: none;
  color: rgb(37,37,37);
  font-size: 1.4rem;
  font-weight: 400;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: rgb(37,37,37);
}


@media screen and (min-width: 360px) {
  .side-drawer {
    max-width: 240px;
  }
}

@media screen and (min-width: 1025px) {
  .side-drawer {
    display: none;
    max-width: 280px;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 100;
  top: 0;
  left: 0;
}

.backdrop:hover {
  background-color: rgba(0, 0, 0, 0.0);
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap-reverse;
  padding: 3rem 1rem;
}
.footer-horizontal-line {
  display: none;
}
.footer-logo-social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 2rem;
}
.footer-social-network-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}
.footer .footer-logo {
  width: 180px;
}
.social-network-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.social-network-row.icons-container {
  width: 50%;
  align-self: center;
  justify-content: space-around;
}
.social-network-title {
  font-size: 1.1rem;
  margin: 2rem auto;
}
.social-network-icon {
  width: 30%;
  max-width: 60px;
  margin: 8px;
}
.social-network-img {
  width: 100%;
  transition: opacity 0.3s;
}
.social-network-img:hover {
  opacity: 0.5;
}
.footer-items-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-around;
}
.footer-sub-items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.footer-item-container {
  flex-flow: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 3rem;
}
.footer-items-container a {
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
}
.footer-items-container a:hover {
  color: #4b4b4b;
  text-decoration: none;
}
.footer-item-link {
  font-weight: 650;
}
.footer-item-container p {
  font-size: 1.2rem;
}
.logo-corfo img {
  width: 200px;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .footer {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    padding: 2rem 0;
  }
  .footer-horizontal-line {
    display: block;
    width: 40vw;
    height: 2px;
    background-color: #73b4e6;
  }
  .footer .footer-logo {
    width: 160px;
    margin-left: 15px;
  }
  .footer-logo-social-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 0;
  }
  .social-network-title{
    margin:0;
  }
  .footer-social-network-container {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: wrap;
    padding-left: 20px;
  }
  .social-network-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .social-network-row.icons-container {
    width: 50%;
    align-self: flex-start;
    justify-content: flex-start;
  }
  .footer-items-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
  .footer-item-container {
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
  }
  .footer-sub-items-container {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer-item-container a {
    font-size: 1rem;
  }
  .footer-item-container p {
    font-size: 1rem;
  }
}

@font-face {
  font-family: 'lemonDrop';
  src: url('/fonts/Lemondrop.ttf');
}

:root {
  --light-green: #2fb984;
  --dark-blue: #14202d;
  --pink: #fc729a;
  --white: #ffffff;
  --light-grey: #9b9b9b;
  --light-grey-bg: #ececec;

  --text-light-green: #4bb676;
  --text-black: #252525;
  --text-error: #f16856;
}

html {
  color: #252525;
  color: var(--text-black);
}

button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

button:hover {
  /* opacity: 0.7; */
  background-color: #2fb984;
  background-color: var(--light-green);
  border: none;
  color: #ffffff;
  color: var(--white);
}

select {
  width: 150px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #c6c6c6;
  padding-left: 10px;
}

input {
  height: 30px;
  border-radius: 2px;
  border: 1px solid #c6c6c6;
  padding-left: 10px;
}

textarea {
  height: 100px;
  border-radius: 2px;
  border: 1px solid #c6c6c6;
  padding: 10px;
  width: 100%;
}

body {
  color: #252525;
  color: var(--text-black);
  font-family: 'Source Sans Pro';
}

.title {
  font-family: 'lemonDrop';
}

.dark-blue-button {
  height: 30px;
  background-color: #14202d;
  background-color: var(--dark-blue);
  width: 100px;
  border-radius: 2px;
  cursor: pointer;
  color: #ffffff;
  color: var(--white);
}

.transparent-button {
  height: 30px;
  border: 1px solid #14202d;
  border: 1px solid var(--dark-blue);
  width: 100px;
  border-radius: 2px;
  cursor: pointer;
}

.light-green-button {
  height: 30px;
  background-color: #2fb984;
  background-color: var(--light-green);
  width: 100px;
  border-radius: 2px;
  cursor: pointer;
  color: #ffffff;
  color: var(--white);
}

.add-to-cart-button {
  width: 140px;
  min-width: 140px;
  text-align: left;
  padding-left: 10px;
  background-image: url('/img/cart-icon-white.png');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 15px;
}

.error-message {
  color: #f16856;
  color: var(--text-error);
  font-size: 12px;
  padding-bottom: 10px;
}

